export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1"},{"name":"msapplication-TileColor","content":"#FFFFFF"},{"name":"msapplication-TileImage","content":"/favicon/cs-favicon/cs-favicon144.png"},{"name":"msapplication-config","content":"/browserconfig.xml"},{"name":"Author","content":"CandleScience"},{"property":"og:site_name","content":"CandleScience - Candle and Soap Making Supplies"},{"property":"twitter:site","content":"@CandleScience"},{"property":"twitter:creator","content":"@CandleScience"},{"name":"description","content":"CandleScience Candle Making Supplies - Everything you'll need for candle making, plus expert advice. Wholesale prices with same day shipping on fragrance oils, soy wax, and more."},{"name":"keywords","content":"Soy Wax, Candle Fragrance Oils, Candle Fragrance Oil, Candle Jars, Candle Making Supplies, Candle Science"}],"link":[{"rel":"search","type":"application/opensearchdescription+xml","href":"https://www.saynotoparmesan.com/opensearch.xml","title":"CandleScience"},{"rel":"icon","href":"/favicon/cs-favicon32.png","sizes":"32x32"},{"rel":"icon","href":"/favicon/cs-favicon57.png","sizes":"57x57"},{"rel":"icon","href":"/favicon/cs-favicon76.png","sizes":"76x76"},{"rel":"icon","href":"/favicon/cs-favicon96.png","sizes":"96x96"},{"rel":"icon","href":"/favicon/cs-favicon128.png","sizes":"128x128"},{"rel":"icon","href":"/favicon/cs-favicon192.png","sizes":"192x192"},{"rel":"icon","href":"/favicon/cs-favicon228.png","sizes":"228x228"},{"rel":"shortcut icon","href":"/favicon/cs-favicon196.png","sizes":"196x196"},{"rel":"apple-touch-icon","href":"/favicon/cs-favicon120.png","sizes":"120x120"},{"rel":"apple-touch-icon","href":"/favicon/cs-favicon180.png","sizes":"180x180"}],"style":[],"script":[{"type":"text/javascript","src":"https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=","async":true,"defer":true}],"noscript":[]}

export const appBaseUrl = undefined

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false